import {
  Form,
  Revision,
  PublicData,
  decodeSecret,
  FormInterface,
  PublicDataInterface,
  RevisionInterface,
  SessionInterface
} from '@churni/core';
import { Box, Container, Flex, Text, Link, Icon } from '@churni/styleguide';
import { useDatabase, useDocumentOnce } from '@churni/fire';
import * as React from 'react';
import { useSessionState, LogoWhite } from '@churni/common';
import { Session as SessionModel } from '@churni/core';
import * as firebase from 'firebase/app';
import { Helmet } from 'react-helmet';
import * as querystring from 'querystring';

import { urls } from '../config';
import { useCrisp, demoValue } from '@churni/common';
import Editor from '@churni/editor';

export default function Session(props: any) {
  const { secret } = props;

  if (secret === 'demo') {
    return <SessionDemo />;
  }

  return <SessionWrapper {...props} />;
}

export function SessionDemo() {
  const pageState = useSessionState(
    async () => {
      window.location.href = `${urls.website}/app`;
    },
    () => {
      window.location.href = `${urls.website}/app`;
    }
  );

  const { action } = useCrisp();

  React.useEffect(() => {
    action(() => {
      const data = window.$crisp.get('session:data');

      if (!data.demo) {
        window.$crisp.push(['do', 'chat:show']);
        window.$crisp.push([
          'do',
          'message:show',
          [
            'text',
            `Thank your for trying our demo :)	
                	
            You can integrate Chatbots & Embeds in your cancellation survey to engage and retain more customers.
            `
          ]
        ]);
        window.$crisp.push(['set', 'session:data', [[['demo', true]]]]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <script>
          {`window.$crisp = [];
          window.CRISP_WEBSITE_ID = '3c3a6b2d-7348-4f3b-8fc6-d9613588d1fa';
          
          (function() {
            const d = document;
            const s = d.createElement('script');
            s.src = 'https://client.crisp.chat/l.js';
            s.async = 1;
            d.getElementsByTagName('head')[0].appendChild(s);
          })();
          
      
          `}
        </script>
      </Helmet>
      <SessionContent initialValue={demoValue} pageState={pageState} />
    </>
  );
}

export function SessionWrapper(props: any) {
  const { secret } = props;

  const { sessionID } = decodeSecret<{
    sessionID: string;
  }>(secret);

  const db = useDatabase();

  const editor = React.useRef();
  const container = React.useRef();

  const session = useDocumentOnce<SessionInterface>(
    SessionModel.queryByID(db)(sessionID)
  );
  const form = useDocumentOnce<FormInterface>(
    Form.queryByID(db)(session.companyID, session.formID)
  );
  const publicData = useDocumentOnce<PublicDataInterface>(
    PublicData.query(db)(session.companyID, session.environmentID)
  );
  const revision = useDocumentOnce<RevisionInterface>(
    Revision.queryByID(db)(
      session.companyID,
      session.formID,
      form.primaryRevision
    )
  );

  const variables = Object.entries(session.variables).reduce(
    (acc, [key, value]) => {
      return {
        ...acc,
        [key]: value
      };
    },
    {}
  );

  // If callback URL contain query string, merge it with status
  const mergeReasonWithURL = (saveURL: string, reason: string) => {
    const url = new URL(saveURL);
    const searchParams = url.search
      ? querystring.parse(url.search.slice(1))
      : {};

    searchParams['reason'] = reason;

    return `${url.origin}${url.pathname}?${querystring.stringify(
      searchParams
    )}`;
  };

  const pageState = useSessionState(
    async () => {
      await onSave('canceled');
      window.location.href = session.cancelConfirmationURL;
    },
    async (reason: string) => {
      await onSave('saved', reason);
      window.location.href = mergeReasonWithURL(session.saveReturnURL, reason);
    }
  );

  React.useEffect(() => {
    // focus container to allow shortcuts
    if (container.current) {
      container.current.focus();
    }
  }, []);

  const onSave = async (status: string, saveReason?: string) => {
    const updateProps = {
      status,
      questions: editor.current.getAnswers(),
      completedAt: firebase.firestore.FieldValue.serverTimestamp()
    };

    await SessionModel.queryByID(db)(session.id).update(
      saveReason ? { ...updateProps, saveReason } : updateProps
    );
  };

  const apps = publicData ? publicData.apps : null;

  React.useEffect(() => {
    // window.customer = session.customer;
    // console.log(session.customer);
    // if (publicData && publicData.script) {
    //   const frag = document
    //     .createRange()
    //     .createContextualFragment(publicData.script);
    //   document.body.appendChild(frag);
    // }
  }, []);

  if (session.status !== 'pending') {
    return (
      <Flex
        css={{ minHeight: '100vh' }}
        alignItems="center"
        justifyContent="center"
      >
        <Box elevation={2} p={3}>
          <Box>
            <Text variant="subtitle3">
              This survey has already been submitted.
            </Text>
          </Box>
          <Box mt={3}>
            <Link variant="primary" as={'a'} href={session.saveReturnURL}>
              <Text variant="subtitle3" icon={<Icon.ArrowLeft />}>
                Take me back to my account
              </Text>
            </Link>
          </Box>
        </Box>
      </Flex>
    );
  }

  return (
    <>
      <Helmet>
        {apps && apps.intercom && apps.intercom.enabled && (
          <>
            <script>
              {`
              window.intercomSettings = {
                app_id: "${apps.intercom.app_id}",
                name: "${session.customer.name}", // Full name
                email: "${session.customer.email}", // Email address
              };
            `}
            </script>
            <script>
              {`(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`}
            </script>
          </>
        )}
        {apps && apps.crisp && apps.crisp.enabled && (
          <script>
            {`window.$crisp = [];
          window.CRISP_WEBSITE_ID = "${apps.crisp.app_id}";
          
          (function() {
            const d = document;
            const s = d.createElement('script');
            s.src = 'https://client.crisp.chat/l.js';
            s.async = 1;
            d.getElementsByTagName('head')[0].appendChild(s);

            

            window.$crisp.push(["set", "user:email", ["${session.customer.email}"]])
            window.$crisp.push(["set", "user:company", ["${session.customer.name}"]])
          })();
          


          
          `}
          </script>
        )}
      </Helmet>
      <SessionContent
        ref={editor}
        variables={variables}
        initialValue={revision.value}
        pageState={pageState}
      />
    </>
  );
}

export const SessionContent = React.forwardRef(function SessionContentWithRef(
  props: {
    variables: any;
    initialValue: any;

    pageState: any;
  },
  ref: React.Ref<any>
): React.ReactElement {
  const { variables, initialValue, pageState } = props;

  return (
    <Flex flexDirection={'column'} sx={{ minHeight: '100vh' }}>
      <Flex flex={1}>
        <Container maxWidth={768} py={5} flex={1}>
          <Editor
            variables={variables}
            ref={ref}
            initialValue={initialValue}
            page={pageState}
            mode="answer"
          />
        </Container>
      </Flex>
      <Link
        as="a"
        sx={{ p: 2, mx: 'auto', textAlign: 'center' }}
        href={'https://www.churni.io'}
        target="_blank"
      >
        <Flex alignItems="center">
          <Box></Box>
          <Text variant={'body3'}>Powered by</Text>
          <Box as={LogoWhite} width={60} ml={1}></Box>
        </Flex>
      </Link>
    </Flex>
  );
});
