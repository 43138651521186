import { Suspense } from '@churni/styleguide';
import { Router, LoadingPage } from '@churni/common';
import * as React from 'react';
import { Global } from '@emotion/core';
import Session from './Session';
import { SEO } from './SEO';
import { ErrorWrapper } from '@churni/common/src';

export function SessionRouter() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <SEO />
      <Global
        styles={theme => {
          return {
            body: {
              fontFamily: theme.fonts.body,
              fontSize: theme.fontSizes[theme.text.body1.fontSize],
              fontWeight: theme.fontWeights[theme.text.body1.fontWeight],
              letterSpacing: theme.text.body1.letterSpacing,
              color: theme.colors.text,
              backgroundColor: theme.colors.background,
              margin: 0,
              padding: 0,
              overflowX: 'hidden',
              overflowY: 'scroll'
            }
          };
        }}
      />
      <ErrorWrapper>
        <Router>
          <Session path={'/s/:secret'} />
          <RedirectToChurni path={'*'} />
        </Router>
      </ErrorWrapper>
    </Suspense>
  );
}

export function RedirectToChurni(props: {}): React.ReactElement {
  React.useEffect(() => {
    window.location.href = 'https://www.churni.io';
  }, []);
  return null;
}
