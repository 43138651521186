import * as React from 'react';
import { SentryProvider } from '@churni/common';
import { SessionRouter } from '../components';

export default function Session() {
  return (
    <>
      <SentryProvider />
      <SessionRouter />
    </>
  );
}
