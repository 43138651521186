export function useCrisp() {
  const action = (callback: () => void) => {
    if (!window.$crisp) {
      return;
    }
    if (window.$crisp.get) {
      callback();
    } else {
      window.$crisp.push(['on', 'session:loaded', callback]);
    }
  };

  return {
    action
  };
}
