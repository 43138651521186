import * as crypto from 'crypto';

const algorithm = 'aes-256-ctr';

const secret = '9*uohf>Wj,vxA3D6.xZ62au6+RP,uKua';

/*
 * Generate/Decode a secret
 */
export function generateSecret(props: object) {
  const cipher = crypto.createCipheriv(algorithm, secret, secret.slice(0, 16));
  let crypted = cipher.update(JSON.stringify(props), 'utf8', 'hex');
  crypted += cipher.final('hex');
  return crypted;
}

export function decodeSecret<T>(string: string): T {
  const decipher = crypto.createDecipheriv(
    algorithm,
    secret,
    secret.slice(0, 16)
  );
  let dec = decipher.update(string, 'hex', 'utf8');
  dec += decipher.final('utf8');
  return JSON.parse(dec);
}
