import { firestore } from 'firebase';
import { genID } from '../helpers';

export interface AnswerInterface {
  id?: string;
  index?: string;
  text: string;
}

export interface QuestionInterface {
  type: string;
  id: string;
  answers: AnswerInterface[];
}

export interface CustomerInterface {
  name: string;
  domain: string;
  id: string;
  createdAt: firestore.Timestamp;
  email: string;
}

export interface SessionInterface {
  id: string;
  status: 'pending' | 'canceled' | 'saved';
  saveReason?: string;
  cancelConfirmationURL: string;
  saveReturnURL: string;
  revisionID: string;
  formID: string;
  companyID: string;
  environmentID: string;
  questions: { [questionID: string]: QuestionInterface };
  customer: CustomerInterface;
  createdAt?: firestore.Timestamp;
  completedAt?: firestore.Timestamp;
  variables: { [id: string]: string | number };
}

export const Session = {
  create(form: Partial<SessionInterface> = {}): SessionInterface {
    const defaultValues: SessionInterface = {
      id: genID('session'),
      status: 'pending',
      cancelConfirmationURL: '',
      saveReturnURL: '',
      revisionID: '',
      formID: '',
      companyID: '',
      environmentID: '',
      customer: {
        name: '',
        domain: '',
        id: '',
        createdAt: null,
        email: ''
      },
      questions: {},
      variables: {}
    };

    return {
      ...defaultValues,
      ...form
    };
  },
  queryByID(db: firestore.Firestore) {
    return (sessionID: string) => {
      return db.collection('sessions').doc(sessionID);
    };
  }
};
